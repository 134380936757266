import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../common/business/auth/services/auth.service';

/**
 * Guard to avoid accessing routes without the required permission.
 */
export const permissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const permission = route.data.permission as string | string[];

  return inject(AuthService)
    .getUserInfo()
    .pipe(
      map((u) => u.permissions),
      map((p) => {
        if (typeof permission === 'string') {
          return p.some((pp) => pp === permission);
        }

        return permission.every((pp) => p.includes(pp));
      })
    );
};
